.toggleButton {
  display: inline-block;
  border-radius: 1rem;
  line-height: 2rem;
  padding: 0 1rem;
  background-color: white;
  cursor: pointer;

  &:hover {
    background-color: #efefef;
  }

  &.checked {
    background-color: #dddddd;
  }

  + .toggleButton {
    margin-left: 1rem;
  }
}
