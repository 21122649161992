.notification {
    width: 300px;
    max-width: calc(100vw - 60px);
    position: fixed;
    top: calc(4rem + 20px);
    left: 50vw;
    transform: translateX(-50%);
    background-color: #F5F5F5;
    color: #000;
    font-weight: 500;
    font-size: 1.25rem;
    padding: 1rem 1.25rem;
    border-radius: 0.8rem;
}