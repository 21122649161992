@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.logo.animated {
  margin: auto;
  animation: rotate 2s linear infinite;
}
