@import '@/variables.scss';

thead > tr {
  .column {
    &.orderable {
      cursor: pointer;

      &::after {
        content: '';
        width: toRem(16);
        height: toRem(16);
        margin-bottom: toRem(-2);
        display: inline-block;
        background-size: contain;
        margin-left: toRem(4);
      }

      &.asc::after {
        background-image: url('../../public/assets/images/orderable-asc.svg');
      }

      &.desc::after {
        background-image: url('../../public/assets/images/orderable-desc.svg');
      }

      &:not(.asc):not(.desc)::after {
        background-image: url('../../public/assets/images/orderable.svg');
      }
    }
  }
}
