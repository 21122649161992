$fontWeight_Thin: 100;
$fontWeight_ExtraLight: 200;
$fontWeight_Light: 300;
$fontWeight_Regular: 400;
$fontWeight_Medium: 500;
$fontWeight_SemiBold: 600;
$fontWeight_Bold: 700;
$fontWeight_ExtraBold: 800;
$fontWeight_Black: 900;

$primary: #bc3df4;
$gray: #cfcfcf;
$success: #32efbe;
$dark: #414042;
$darkGray: #ccc;
$danger: #ec4849;
$yellow: #ffd07b;

@mixin tagActive {
  &.active {
    background-color: $darkGray;
    transform: rotateX(180deg);
  }
}

@function toRem($sizeInPx) {
  @return calc($sizeInPx / 12 * 1rem);
}
