@import '@/variables';

@mixin readMore {
  @include hideByReadMore();
  @include noMarginTopByReadMore();
  @include noMarginBottomByReadMore();
}

@mixin hideByReadMore {
  transition: all 0.5s ease-in-out;
  overflow-y: hidden;

  &.read-more {
    max-height: 0;
  }
}

@mixin noMarginTopByReadMore {
  transition: all 0.5s ease-in-out;
  overflow-y: hidden;

  &.read-more {
    margin-top: 0;
  }
}

@mixin noMarginBottomByReadMore {
  transition: all 0.5s ease-in-out;
  overflow-y: hidden;

  &.read-more {
    margin-bottom: 0;
  }
}

.title {
  font-size: toRem(22);
  font-weight: $fontWeight_Black;
  text-align: center;
  max-height: toRem(100);

  @include readMore();
}

.sub-title {
  margin-top: toRem(6);
  font-size: toRem(14);
  text-align: center;
  max-height: toRem(100);

  @include readMore();
}

.tabs {
  display: flex;
  flex-direction: row;
  padding: toRem(12);
  list-style: none;
  text-align: center;

  @include noMarginTopByReadMore();

  &.window {
    margin: toRem(16) 0 0;
    flex-wrap: wrap;

    > li {
      font-size: toRem(12);
      flex: calc(50% - toRem(4)) 1 1;
      margin-bottom: toRem(8);
      padding: toRem(16);
      line-height: toRem(13);

      &:nth-child(2n) {
        border-radius: 0 toRem(10) toRem(10) 0;
        margin-left: toRem(8);
      }

      &:nth-child(2n-1) {
        border-radius: toRem(10) 0 0 toRem(10);
      }
    }
  }

  &:not(.window) {
    margin: toRem(16) toRem(-30) 0;

    > li {
      border-radius: toRem(5);
      font-size: toRem(8);
      flex: 25% 1 1;
      line-height: toRem(10);

      &:not(:last-child) {
        margin-right: toRem(8);
      }

      &.active {
        transform: scale(1.2);
      }
    }
  }

  > li {
    padding: toRem(8) toRem(4);
    font-weight: $fontWeight_SemiBold;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;
    flex-direction: column;

    &.active {
      opacity: 1;
      box-shadow: 0 toRem(4) toRem(4) #0008;
      z-index: 1;
    }

    > img {
      margin-bottom: toRem(8);
      max-height: toRem(32);

      @include readMore();
    }
  }
}

.content-area {
  margin: 0 toRem(-30);
}

.reports {
  position: relative;
  padding: 0 0;
  transition: margin-top 0.5s ease-in-out;

  &.read-more {
    margin-top: toRem(20);

    .report {
      > div {
        margin: 0 toRem(16);

        > div {
          background-color: white;
          max-height: 500vh;
        }
      }
    }
  }

  .report {
    padding: 0 toRem(4);

    &.primary {
      --main-color: #{$primary};
      > div > h2 {
        background-color: $primary;
      }
    }

    &.yellow {
      --main-color: #{$yellow};
      > div > h2 {
        background-color: $yellow;
        color: $dark;
      }
    }

    &.success {
      --main-color: #{$success};
      > div > h2 {
        background-color: $success;
        color: $dark;
      }
    }

    &.dark {
      --main-color: #{$dark};
      > div > h2 {
        background-color: $dark;
      }
    }

    > div {
      background-color: #f5f5f5;
      border-radius: toRem(5);
      position: relative;
      padding: toRem(8);

      > h2 {
        margin: 0;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        line-height: toRem(15);
        color: white;
        font-size: toRem(13);
        font-weight: $fontWeight_SemiBold;
        padding: toRem(8) toRem(10);
        border-radius: toRem(5);
        text-align: center;
        z-index: 1;
      }
    }
  }
}
