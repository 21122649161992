@import '@/variables.scss';

.open {
  .tags {
    max-height: 0;
    padding: 0;
    overflow-y: hidden;
  }

  .content {
    margin-top: toRem(32);
    font-size: toRem(14);
  }

  &.lazy-typing {
    overflow-y: auto !important;
  }
}

.no-tag {
  .tags {
    padding: 8px 0 !important;
  }
}

.tags {
  background-color: white;
  padding: toRem(45) toRem(16) toRem(18);
  display: flex;
  flex-direction: column;
  border-radius: toRem(5);
  transition: all 0.5s ease-in-out;
  max-height: 100vh;
  margin-bottom: 12px;

  > span {
    border: solid 1px var(--main-color);
    border-radius: toRem(10);
    line-height: toRem(15);
    padding: toRem(6);
    font-size: toRem(14);
    font-weight: $fontWeight_Light;
    text-align: center;

    &:not(:first-child) {
      margin-top: toRem(8);
    }
  }
}

.content {
  display: block;
  font-size: toRem(14);
  font-weight: $fontWeight_ExtraLight;
  color: black;
  padding: 0 toRem(12);
  transition: all 0.5s ease-in-out;
  flex: auto 1 1;

  * {
    font-weight: inherit;
    font-size: inherit;
  }

  ul,
  ol {
    padding-left: toRem(16);
  }
}

.btn-read-more {
  color: $primary;
  font-size: toRem(11);
  padding: toRem(8);
  border: 0;
  background: none;
  flex: 46px 0 0;
  display: block;
  text-align: right;
}

.lazy-typing {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
